import { post } from '../../utils/api'
export const login = (data) => {
    return post(`/auth/login`, data)
};

export const forgotPassword = (data) => {
    return post(`/auth/forgot-password`, data)
};

export const changePassword = (data) => {
    return post(`/profile/change-password`, data)
};

export const authApi = {
    login,
    forgotPassword,
    changePassword
}