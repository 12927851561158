import React from 'react'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaAngleLeft } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";
import { FaPlay } from "react-icons/fa";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useNavigate } from 'react-router-dom';

const HeaderSwiper = ({ carouselData }) => {
    const Navigate = useNavigate();
    return (
        <React.Fragment>
            <div className='relative'>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    spaceBetween={10}
                    slidesPerView={1}
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }}
                    // navigation
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}
                    grabCursor={true}
                    autoplay={{ delay: 3000, disableOnInteraction: true }}
                >
                    {
                        carouselData?.map((data, index) => {
                            var pattern = /^(.*?)\s*\(/;
                            var Header = pattern.exec(data?.Name);
                            return (
                                <>
                                    <SwiperSlide key={index}>
                                        <div className='h-[200px] md:h-[550px] object-cover'>
                                        <img src={data?.Info?.PosterLink}  className='h-[350px] md:h-[1250px] object-cover' alt='new one' />
                                        </div>
                                        <div className='header_info'>
                                            <div className='header '>
                                                {Header[1]}
                                            </div>
                                            <div className='text-white text-base mobile:text-xs w-3/4 pt-3 md:pt-2 unbounded-font'>
                                                {/*{data?.Name}*/}
                                            </div>
                                            <button onClick={() => Navigate(`/movie/${data.CID}`)} type="button" className="
                                            flex gap-4 items-center focus:outline-none px-10 mt-2 text-black bg-[#FFD700]
                                             hover:bg-red-800 hover:text-white font-bold rounded-lg text-lg mobile:text-xs py-3 mobile:py-2 mobile:px-3
                                              my-2 mobile:my-1"><FaPlay /> Watch Now</button>
                                        </div>
                                    </SwiperSlide>
                                </>
                            )
                        })
                    }

                    <div className="swiper-button-prev"><FaAngleLeft /></div>
                    <div className="swiper-button-next"><FaAngleRight /></div>
                </Swiper>

            </div>
        </React.Fragment >
    )
}

export default HeaderSwiper
