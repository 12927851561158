import { get } from '../../utils/api'
export const latestMovies = (data) => {
    const page = data?.page || 1
    const limit = 12;
    return get(`/list?page=${page}&limit=${limit}`, data)
};
export const recommendMovies = (data) => {
    const page = data?.page || 1
    const limit = 12;
    return get(`/list?page=${page}&limit=${limit}`, data)
};

export const searchMovies = (data) => {
    const query = data?.query || ''
    const limit = data?.limit || 12
    const page = data?.page || 1
    return get(`/search?query=${query}&limit=${limit}&page=${page}`)
};
export const singleMovies = (data) => {
    const id = data?.id || ''
    return get(`/movie/${id}`)
};
export const downloadMovies = (data) => {
    const UID = data?.UID || ''
    return get(`/content/${UID}`)
};

export const moviesApi = {
    latestMovies,
    recommendMovies,
    searchMovies,
    singleMovies,
    downloadMovies,
}