import { combineReducers } from "redux";
import authReducer from "./authReducer";
import moviesReducer from "./movieReducer";


const rootReducer = combineReducers({
    auth: authReducer,
    movies: moviesReducer,
})

export default rootReducer;