import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import logo from './../assets/images/logo.jpg'
import { styled } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu';
import { FaRegUserCircle } from "react-icons/fa";
import Film from '../../assets/images/Colorful.png'


const Navbar = () => {
    const navigate = useNavigate();

    const BoxWrapper = styled(Box)({
        position: 'absolute',
        zIndex: 1,
        width: '100%',
        '.appbar': {
            background: '#2F4F4F',
            padding: '1rem 8rem',
        },
        '.sign-in': {
            background: '#FFD700 ',
            textTransform: 'capitalize',
            padding: '10px 22px', fontWeight: "bold",
            fontSize: '15px',
            fontFamily: 'Unbounded',
            color: '#2F4F4F',
        },
        '.title': {
            fontWeight: 800,
            color: '#fff',
        },
        '.burger-menu': {
            display: 'none',
        },
        ".parent-logo": {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
        },
        '.parent-navbar': {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        '.user': {
            fontSize: '18px',
            marginRight: '10px'
        },
        "@media(min-width: 1px) and (max-width: 426px)": {
            '.title': {
                fontSize: '15px',
                fontWeight: 'semibold',
            },

            '.navigation-title': {
                display: 'none',
            },
            '.burger-menu': {
                display: 'block',
            },
            '.sign-in': {
                display: 'none'
            },
            '.appbar': {
                padding: '1rem'
            }
        }
    })

    return (
        <BoxWrapper>
            <AppBar elevation={0} position="static" className='appbar'>
                {/* <Toolbar> */}
                <Box className='parent-navbar'>
                    <Box className='parent-logo' onClick={() => navigate('/')}>
                        {/* <Typography variant="h5" className='title' component="div" sx={{ flexGrow: 1 }}>
                            Nalandii-Web
                        </Typography> */}
                        <img src={Film} width={70} className='pr-4' alt='new-film' />
                        <div class="text-4xl mobile:text-2xl font-bold text-white mb-1 unbounded-font">
                            Movie<span class="pl-1 text-[#FFD700]">Strom</span>
                        </div>
                    </Box>
                    <Box className='navigation-title'>

                        {/* <ul>
                            <li onClick={() => navigate("/")}>

                            </li>
                            <li onClick={() => navigate("/about")}>
                                About-Us
                            </li>
                            <li onClick={() => navigate("/contact")}>
                                Contect-Us
                            </li>
                        </ul> */}
                    </Box>
                    <Box className='burger-menu'>
                        {/* //! for future use hamburger menu */}
                        {/* <HamburgerMenu /> */}
                    </Box>
                    <Button className='sign-in' color="inherit"> <FaRegUserCircle className='user text-[#2F4F4F]' /> Sign In</Button>
                </Box>
                {/* </Toolbar> */}
            </AppBar>
        </BoxWrapper>
    );
}

export default Navbar;
