
export const LATEST_MOVIES = 'LATEST_MOVIES';
export const LATEST_MOVIES_SUCCESS = 'LATEST_MOVIES_SUCCESS';
export const LATEST_MOVIES_FAIL = 'LATEST_MOVIES_FAIL';

export const RECOMMEND_MOVIES = 'RECOMMEND_MOVIES';
export const RECOMMEND_MOVIES_SUCCESS = 'RECOMMEND_MOVIES_SUCCESS';
export const RECOMMEND_MOVIES_FAIL = 'RECOMMEND_MOVIES_FAIL';

export const SEARCH_MOVIES = 'SEARCH_MOVIES';
export const SEARCH_MOVIES_SUCCESS = 'SEARCH_MOVIES_SUCCESS';
export const SEARCH_MOVIES_FAIL = 'SEARCH_MOVIES_FAIL';

export const SINGLE_MOVIES = 'SINGLE_MOVIES';
export const SINGLE_MOVIES_SUCCESS = 'SINGLE_MOVIES_SUCCESS';
export const SINGLE_MOVIES_FAIL = 'SINGLE_MOVIES_FAIL';

export const DOWNLOAD_MOVIES = 'DOWNLOAD_MOVIES';
export const DOWNLOAD_MOVIES_SUCCESS = 'DOWNLOAD_MOVIES_SUCCESS';
export const DOWNLOAD_MOVIES_FAIL = 'DOWNLOAD_MOVIES_FAIL';

export const MOVIES = 'MOVIES';
export const MOVIES_SUCCESS = 'MOVIES_SUCCESS';
export const MOVIES_FAIL = 'MOVIES_FAIL';

export const TOP_MOVIES = 'TOP_MOVIES';
export const TOP_MOVIES_SUCCESS = 'TOP_MOVIES_SUCCESS';
export const TOP_MOVIES_FAIL = 'TOP_MOVIES_FAIL';

export const WEB_SERISE = 'WEB_SERISE';
export const WEB_SERISE_SUCCESS = 'WEB_SERISE_SUCCESS';
export const WEB_SERISE_FAIL = 'WEB_SERISE_FAIL';

export const MOVIES_ONLY = 'MOVIES_ONLY';
export const MOVIES_ONLY_SUCCESS = 'MOVIES_ONLY_SUCCESS';
export const MOVIES_ONLY_FAIL = 'MOVIES_ONLY_FAIL';
