import { all, fork } from 'redux-saga/effects';
import auth from './authSaga';
import movies from './moviesSaga';



export default function* rootSaga() {
    yield all([
        fork(auth),
        fork(movies),
    ])
}