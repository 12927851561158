import { get } from '../../utils/api/letestmovie'

// recommend movies  todo : need to change name in future
export const movies = (data) => {
    return get(`/get-recommend-movies`, data)
};

export const getMoviesOnly = (data) => {
    return get(`/get-movies-paginated`, data)
};

export const topmovies = (data) => {
    return get(`/get-top-viewed-movies`, data)
};
export const webserise = (data) => {
    return get(`/get-movies-paginated`, data)
};

export const letestApi = {
    movies,
    topmovies,
    webserise,
    getMoviesOnly,
}
