import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IoMdCloseCircle } from "react-icons/io";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    maxHeight: 600,
    background: 'black',
    color: 'white',
    border: '2px solid #000',
    p: 4,
    overflowY: 'scroll',
    boxShadow: '0px 0px 10px rgb(194 157 0)',
    borderRadius: '6px'
};

const mediaQuery = '@media screen and (max-width: 600px)';

const responsiveStyle = {
    [mediaQuery]: {
        width: '90%', // Adjusted width for smaller screens
        maxHeight: '70vh', // Adjusted maximum height for smaller screens
    },
};
const combinedStyles = { ...style, ...responsiveStyle };

const QualityModal = ({ open, handleClose, info }) => {

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                    <Box sx={combinedStyles}>
                    <button className="btn btn-lg btn-circle btn-ghost absolute right-2 top-2 " onClick={handleClose}>
                        <IoMdCloseCircle className=' text-white text-2xl ' /></button>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: "center", fontWeight: 'bold', fontSize: '1.5rem' }}>
                        😎 Here you Check Quality 😎
                    </Typography>
                    <div className='mt-7 grid gap-2 grid-cols-1 md:grid-cols-2 '>
                        {info?.Info?.ScreenShots?.map((data, index) => {
                            return (
                                <div>
                                    <img src={data} alt={index} width={'100%'} />
                                </div>
                            )
                        })}
                    </div>
                </Box>

            </Modal>
        </div>
    );
}

export default QualityModal;
