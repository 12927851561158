import { API } from "../api";
import { call, put, takeLatest } from 'redux-saga/effects';
import { CHANGE_PASSWORD, CHANGE_PASSWORD_FAIL, CHANGE_PASSWORD_SUCCESS, FORGOT_PASSWORD, FORGOT_PASSWORD_FAIL, FORGOT_PASSWORD_SUCCESS, LOGIN, LOGIN_FAIL, LOGIN_SUCCESS } from "../actions/authActions";



function* login(action) {
    try {
        const response = yield call(API.login, action.payload);
        yield put({ type: LOGIN_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: LOGIN_FAIL, payload: { error: e } });
    }
}



function* forgotPassword(action) {
    try {
        const response = yield call(API.forgotPassword, action.payload);
        yield put({ type: FORGOT_PASSWORD_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: FORGOT_PASSWORD_FAIL, payload: { error: e } });
    }
}

function* changePassword(action) {
    try {
        const response = yield call(API.changePassword, action.payload);
        yield put({ type: CHANGE_PASSWORD_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: CHANGE_PASSWORD_FAIL, payload: { error: e } });
    }
}


function* auth() {
    yield takeLatest(LOGIN, login);
    yield takeLatest(FORGOT_PASSWORD, forgotPassword);
    yield takeLatest(CHANGE_PASSWORD, changePassword)
}
export default auth;