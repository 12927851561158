import { CHANGE_PASSWORD, CHANGE_PASSWORD_FAIL, CHANGE_PASSWORD_SUCCESS, FORGOT_PASSWORD, FORGOT_PASSWORD_FAIL, FORGOT_PASSWORD_SUCCESS, LOGIN, LOGIN_FAIL, LOGIN_SUCCESS } from "../actions/authActions";
import { cookie } from "../../utils/cookies/cookies";

const initialState = {
    loginData: {},
    forgotData: {},
    changePasswordData: {},
    isLoading: false,
};

const authReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case LOGIN: {
            return {
                ...state,
                isLoading: true
            };
        }
        case LOGIN_SUCCESS: {
            if (payload.tokens) {
                cookie.set('token', payload.tokens.access.token);
            }
            setTimeout(() => {
                // window.location.href = '/billing-summary';
            }, 1000);
            return {
                ...state,
                loginData: payload.user,
                isLoading: false,
            };
        }

        case LOGIN_FAIL: {
            setTimeout(() => {
                // window.location.href = '/';
            }, 1000);
            return {
                ...state,
                isLoading: false
            };
        }
        case FORGOT_PASSWORD: {
            return {
                ...state,
                isLoading: true
            };
        }
        case FORGOT_PASSWORD_SUCCESS: {
            setTimeout(() => {
                // window.location.href = '/';
            }, 1000);

            return {
                ...state,
                forgotData: payload,
                isLoading: false,
            };
        }
        case FORGOT_PASSWORD_FAIL: {
            return {
                ...state,
                isLoading: false
            };
        }

        case CHANGE_PASSWORD: {
            return {
                ...state,
                isLoading: false
            }
        }

        case CHANGE_PASSWORD_SUCCESS: {
            setTimeout(() => {
                window.location.href = '/change-password'
            }, 1000);
            return {
                ...state,
                changePasswordData: payload,
                isLoading: false
            }
        }

        case CHANGE_PASSWORD_FAIL: {
            return {
                ...state,
                isLoading: false
            }
        }


        default: {
            return { ...state };
        }
    }
};
export default authReducer;