/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { IoIosStar } from "react-icons/io";
import VideoDemo from '../small/VideoPlayer';
import QualityModal from '../small/Quality';
import { DOWNLOAD_MOVIES } from '../../redux/actions/moviesActions';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from "@mui/material";
import CustomCarousel from "../small/carousels/CustomCarousel";
import Loader from "../small/Loader";
import soon from './../../assets/images/comming.png'
import mixpanel from "mixpanel-browser";


const Movieinfo = ({ info, isWebSeries }) => {
    const [isLoading, setIsLoading] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [quality, setQualityOpen] = useState(false);
    const [showLoader, setShowLoader] = useState(false)
    const [Uid, setUid] = useState("");
    const [token, setToken] = useState("");
    const recommendMoviesList = useSelector((state) => state?.movies?.recommendMovies?.Movies);
    useEffect(() => {
        if (Uid) {
            setIsLoading(true);
            donwnloadMovie({
                UID: Uid,
                handleSuccess: (data) => {
                    downloadFile(data?.DownloadLink);

                },
            });
        }
    }, [Uid]);
    const handleClose = () => setQualityOpen(false);
    const handleButtonClick = () => {
        setIsOpen(!isOpen);
    };
    var pattern = /^(.*?)\s*\(/;
    var match = pattern.exec(info?.Name);

    // const movieDownloadBtn = []?.concat(...Object.values(info?.MediaLinks));
    let movieDownloadBtn = [];
    if (info && info?.MediaLinks) {
        for (let key in info.MediaLinks) {
            movieDownloadBtn = movieDownloadBtn.concat(info.MediaLinks[key]);
        }
    }

    const videoFId = movieDownloadBtn?.[0]?.Fid

    let webDownloadBtn = [];
    if (info && info?.EpisodeData) {
        for (let key in info.EpisodeData) {
            webDownloadBtn = webDownloadBtn.concat(info.EpisodeData[key]);
        }
    }
    const WebFId = webDownloadBtn?.[0]?.Name

    // if (info?.EpisodeData) {
    //     info.EpisodeData.forEach((episode, index) => {
    //         console.log(`Episode ${index + 1}: ${episode.Name}`);
    //     });
    // }


    // !movie download api
    const downloadLinks = useSelector((state) => state?.movies?.downloadMovies);
    const dispatch = useDispatch();
    const donwnloadMovie = useCallback((data) => {
        dispatch({ type: DOWNLOAD_MOVIES, payload: data });
    }, [])

    const donwnloadweb = useCallback((data) => {
        dispatch({ type: DOWNLOAD_MOVIES, payload: data });
    }, [])

    // console.log("donwnloadweb",donwnloadMovie)

    const downloadFile = (data) => {
        const link = document.createElement('a');
        link.href = data;
        link.setAttribute('download', '');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        if (Uid) {
            donwnloadMovie({
                UID: Uid,
                handleSuccess: (data) => {
                    downloadFile(data?.DownloadLink);
                }
            })
        }
    }, [Uid]);
    useEffect(() => {
        if (videoFId) {
            donwnloadMovie({
                UID: videoFId,
                handleSuccess: (data) => {
                    setToken(data?.StreamToken)
                    // downloadFile(data);
                }
            })
        }
    }, [videoFId]);


    useEffect(() => {
        if (Uid) {
            donwnloadweb({
                UID: Uid,
                handleSuccess: (data) => {
                    downloadFile(data?.DownloadLink);
                }
            })
        }
    }, [Uid]);
    useEffect(() => {
        if (WebFId) {
            donwnloadweb({
                UID: WebFId,
                handleSuccess: (data) => {
                    setToken(data?.StreamToken)

                }
            })
        }
    }, [WebFId]);
    // get stream token
    console.log("btn", webDownloadBtn)

    useEffect(() => {
        let timer;
        if (showLoader) {
            timer = setTimeout(() => {
                setShowLoader(false)
            }, 5000)
        }
        return () => clearTimeout(timer);
    }, [showLoader])

    return (
        <React.Fragment>
            <div className='flex1 flex mobile:flex-col'>
                <div className='sm:mr-2 md:basis-4/6 mobile:basis-1/2'>
                    {
                        isWebSeries ? <img src={soon} alt='comming-soon' width={'100%'} className='soon-image' /> : <VideoDemo token={token} />
                    }

                </div>

                <div className='pl-2 md:basis-2/6 mobile:basis-1/2'>
                    <div className='text-white text-lg md:text-3xl font-bold pt-1 unbounded-font ml-2 '>
                        {match?.[1]}
                    </div>
                    <div className='flex flex-row-reverse'>
                        <button
                            className="text-white bg-transparent border border-solid border-white hover:bg-[#FFD700] hover:text-black active:bg-yellow-300-600 font-bold uppercase text-sm px-2 py-2 md:px-6 md:py-3 rounded-xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            onClick={() => setQualityOpen(true)}>screenshorts
                        </button>
                    </div>

                    <div className='text-white ml-2 mt-[-32px] md:mt-[-40px] md:text-xl unbounded-font flex gap-2'>
                        <button type="button"
                            class="px-2 py-1 text-sm font-medium text-center bg-[#FFD700] rounded-lg flex gap-1 items-center text-[#2F4F4F]">
                            <IoIosStar />
                            {parseInt(info?.Info?.Rating)}
                        </button>
                        <button type="button"
                            class=" text-[#2F4F4F] px-2 py-1 text-sm font-medium text-center bg-[#FFD700] rounded-lg">HD
                        </button>
                        <div className='text-base'>

                        </div>
                    </div>
                    <div
                        className='text-white mt-3 md:mt-5 md:text-sm unbounded-font text-wrap font-light line-clamp-5'>
                        {info?.Info?.Description}
                    </div>

                    <div className='text-white mt-4'>
                        {/* {info?.description?.slice(0, 10)} */}
                    </div>
                    <div className='text-white mt-2 md:mt-3 md:text-lg unbounded-font flex '>
                        <div className='w-32'>
                            Language:
                        </div>
                        {info?.Info?.Language}
                    </div>
                    <div className='text-white  mt-2 md:mt-3 md:text-lg unbounded-font flex'>
                        <div className='w-32'> Genere:</div>
                        {info?.Info?.Genere}
                    </div>
                    <div className='text-white mt-3 md:mt-3 md:text-lg unbounded-font text-pretty flex'>
                        <div className='w-32'> Quality:
                        </div>
                        {info?.Info?.Quality}
                    </div>
                </div>
            </div>
            {/* //! here downlod loader */}
            {
                showLoader &&
                <Loader />
            }
            <div className='mt-4'>
                <button
                    onClick={() => {
                        mixpanel.track("movie download clicked", {
                            name: match,
                            info: info?.Info?.Description
                        });
                        handleButtonClick();
                    }}
                    className="text-white bg-transparent border border-solid border-white hover:bg-[#FFD700] hover:text-black active:bg-yellow-300-600 font-bold uppercase text-sm px-2 py-2 md:px-6 md:py-3 rounded-xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button">
                    {isOpen ? 'Download' : 'Download'}
                </button>
                {/* //! here buttons */}
                {isOpen && (info?.IsMovie ? (
                    <div className="additional-buttons grid grid-cols-6 mobile:grid-cols-2 gap-1 ml-4">
                        {movieDownloadBtn?.map((button, index) => {
                            return (
                                <button className="
                                text-white bg-transparent border border-solid border-white hover:bg-[#FFD700] hover:text-black
                                 active:bg-[#FFD700] font-bold uppercase text-sm px-3 py-3 md:px-4 md:4 rounded-xl
                                   outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    onClick={() => {
                                        setUid(button?.Fid)
                                        setShowLoader(true)
                                    }}>
                                    {button?.UID}
                                </button>
                            )
                        })}  </div>
                ) : (
                    <div className="additional-buttons grid grid-cols-6 mobile:grid-cols-1 gap-1 ml-4">
                        {/*<DownloadButtons className="border border-2 h-20 w-20 border-white gap-2" buttonData={webDownloadBtn} setUid={setUid} />*/}
                        {/*<div className="additional-buttons grid grid-cols-6 mobile:grid-cols-2 gap-1 ml-4">*/}
                        {webDownloadBtn?.map((button) => {
                            return (
                                // <div className="text-white">{button.Name}
                                <>
                                    <div className="text-white mr-1 mb-1">{button.Name}
                                        {
                                            button['R720'] &&
                                            <button className="
                                text-white bg-transparent border border-solid border-white hover:bg-[#FFD700] hover:text-black
                                 active:bg-[#FFD700] font-bold uppercase text-sm px-3 py-3 md:px-4 md:4 rounded-xl
                                   outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 ml-1"
                                                onClick={() => setUid(button['R720'][0]?.Fid)}>
                                                {`720`}
                                            </button>
                                        }
                                        {
                                            button['R480'] &&
                                            <button
                                                key={"480"} // Ensure each button has a unique key
                                                className="text-white bg-transparent border border-solid border-white hover:bg-[#FFD700]
                                                     hover:text-black active:bg-[#FFD700] font-bold uppercase text-sm px-3 py-3 md:px-4 md:4
                                                      rounded-xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                onClick={() => setUid(button['R480'][0]?.Fid)}>
                                                {480}
                                            </button>
                                        }
                                        {
                                            button['R1080'] &&
                                            <button
                                                key={"1080"} // Ensure each button has a unique key
                                                className="text-white bg-transparent border border-solid border-white hover:bg-[#FFD700]
                                                     hover:text-black active:bg-[#FFD700] font-bold uppercase text-sm px-3 py-3 md:px-4 md:4 rounded-xl outline-none
                                                      focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                onClick={() => setUid(button['R1080'][0]?.Fid)}>
                                                {1080}
                                            </button>
                                        }
                                    </div>
                                </>
                            );
                        })}
                        {/*</div>*/}
                    </div>
                ))}
            </div>
            {/*{isLoading && <Loader />}*/}

            <div
                className='text-white text-2x1 md:text-3xl font-bold mt-8 unbounded-font border-l-4 border-[#FFD700] pl-2 rounded-sm'>
                RECOMMENDED 💥
            </div>
            {
                recommendMoviesList?.length > 0 ?
                    <Box>
                        <CustomCarousel movieDetails={recommendMoviesList} />
                    </Box> : <Loader />
            }


            {/* //! use for future start */}
            {/* <div className='text-white text-3xl font-bold mt-8 unbounded-font border-l-4 border-yellow-500 pl-2 rounded-sm'>
                HD PRINTS ✨
            </div>
            <div className='mt-7 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3'>
                {info?.info?.screenshots?.map((data, index) => {
                    return (
                        <div>
                            <img src={data} alt={index} width={'100%'} />
                        </div>
                    )
                })}
            </div> */}
            {/* //! use for future  end */}

            {quality &&
                <QualityModal open={quality} handleClose={handleClose} info={info} />
            }
        </React.Fragment>
    )

}
export default Movieinfo;
