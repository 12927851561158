import { API } from "../api";
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    DOWNLOAD_MOVIES,
    DOWNLOAD_MOVIES_FAIL,
    DOWNLOAD_MOVIES_SUCCESS,
    LATEST_MOVIES,
    LATEST_MOVIES_FAIL,
    LATEST_MOVIES_SUCCESS,
    MOVIES,
    MOVIES_FAIL, MOVIES_ONLY,
    MOVIES_ONLY_FAIL,
    MOVIES_ONLY_SUCCESS,
    MOVIES_SUCCESS,
    RECOMMEND_MOVIES,
    RECOMMEND_MOVIES_FAIL,
    RECOMMEND_MOVIES_SUCCESS,
    SEARCH_MOVIES,
    SEARCH_MOVIES_FAIL,
    SEARCH_MOVIES_SUCCESS,
    SINGLE_MOVIES,
    SINGLE_MOVIES_FAIL,
    SINGLE_MOVIES_SUCCESS,
    TOP_MOVIES,
    TOP_MOVIES_FAIL,
    TOP_MOVIES_SUCCESS,
    WEB_SERISE,
    WEB_SERISE_FAIL,
    WEB_SERISE_SUCCESS
} from "../actions/moviesActions";



function* latestMovies(action) {
    try {
        const response = yield call(API.latestMovies, action.payload);
        yield put({ type: LATEST_MOVIES_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: LATEST_MOVIES_FAIL, payload: { error: e } });
    }
}
function* recommendMovies(action) {
    try {
        const response = yield call(API.recommendMovies, action.payload);
        yield put({ type: RECOMMEND_MOVIES_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: RECOMMEND_MOVIES_FAIL, payload: { error: e } });
    }
}
function* searchMovies(action) {
    try {
        const response = yield call(API.searchMovies, action.payload);
        yield put({ type: SEARCH_MOVIES_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: SEARCH_MOVIES_FAIL, payload: { error: e } });
    }
}
function* singleMovies(action) {
    try {
        const response = yield call(API.singleMovies, action.payload);
        yield put({ type: SINGLE_MOVIES_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: SINGLE_MOVIES_FAIL, payload: { error: e } });
    }
}
function* downloadMovies(action) {
    const { handleSuccess, ...otherData } = action.payload;
    try {
        const response = yield call(API.downloadMovies, otherData);
        yield put({ type: DOWNLOAD_MOVIES_SUCCESS, payload: response.data });
        if (response?.data) {
            // handleSuccess(response?.data?.DownloadLink)
            handleSuccess(response?.data)
        }
    } catch (e) {
        yield put({ type: DOWNLOAD_MOVIES_FAIL, payload: { error: e } });
    }
}

function* movie(action) {
    try {
        const response = yield call(API.movies, action.payload);
        yield put({ type: MOVIES_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: MOVIES_FAIL, payload: { error: e } });
    }
}

function* topmovie(action) {
    try {
        const response = yield call(API.topmovies, action.payload);
        yield put({ type: TOP_MOVIES_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: TOP_MOVIES_FAIL, payload: { error: e } });
    }
}

function* webserise(action) {
    try {
        const response = yield call(API.webserise, action.payload);
        yield put({ type: WEB_SERISE_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: WEB_SERISE_FAIL, payload: { error: e } });
    }
}

function* onlyMovies(action) {
    try {
        const response = yield call(API.getMoviesOnly, action.payload);
        yield put({ type: MOVIES_ONLY_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: MOVIES_ONLY_FAIL, payload: { error: e } });
    }
}


function* movies() {
    yield takeLatest(LATEST_MOVIES, latestMovies);
    yield takeLatest(RECOMMEND_MOVIES, recommendMovies);
    yield takeLatest(SEARCH_MOVIES, searchMovies);
    yield takeLatest(SINGLE_MOVIES, singleMovies);
    yield takeLatest(DOWNLOAD_MOVIES, downloadMovies);
    yield takeLatest(MOVIES, movie);
    yield takeLatest(TOP_MOVIES, topmovie);
    yield takeLatest(WEB_SERISE, webserise);
    yield takeLatest(MOVIES_ONLY, onlyMovies);
}
export default movies;
