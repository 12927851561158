import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import Loader from "./Loader";

const VideoDemo = ({ token }) => {
    // const [isMuted, setIsMuted] = useState(false);
    // const url = 'https://video.ddlmovies.online/?streamToken=U2FsdGVkX18N%2BDRK%2FxUnWO4THMrdUfrKpeF9yGCnYwm7FCTKirmM%2FCmC88uC9TE2LU%2BgQDC3jSO8wNm8D2LrEVMLkAVO7DGPvUaZ4iEVyaijECndk91C4YQ%2BNXI7XOimce57NVdTi4uNqa0890%2F36WWLL337F%2BoaW4lyFrcN37AxxV9MpaWqTcnFEhVhVVKTiqTgTiYuLocapyrpyfesbi57Dfmh3ceB0f7bflMJuEvNUcNGQIHuRkk0V0LK2pI9'
    const url = `https://video.ddlmovies.online/?streamToken=${encodeURIComponent(token)}`
    // const url = 'https://static.bunnycdn.ru/i/cache/images/f/fa/fab73e941d763a6aa4cc34cb2cfbb08d.jpg-w1024'
    return (
        <div className="border border-white p-1.5 border-dashed">
            {token ?
                <Video autoPlay loop muted
                    controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                    poster="https://images.unsplash.com/photo-1485846234645-a62644f84728?q=80&w=2059&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    onCanPlayThrough={() => {
                        // Do stuff
                    }}>
                    <source
                        src={url ? url : ""}
                        // src={'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4'}
                        type="video/webm" />
                    <track label="English" kind="subtitles" srcLang="en" src="http://source.vtt" default />
                </Video>
                : <Loader />
            }
            {/* <video width={'100%'} height="800" controls loop autoPlay muted={isMuted} className="rounded-xl">
                <source src={url} type="video/mp4" />
            </video> */}
            {/* //! for future use */}
            {/* <button onClick={() => setIsMuted(!isMuted)}>
                {isMuted ? "Unmute" : "Mute"}
            </button> */}
        </div>
    );
};

export default VideoDemo;
