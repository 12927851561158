import React from 'react'

const Loader = () => {
    return (
        <React.Fragment>
            <div class='flex space-x-2 justify-center items-center h-screen dark:invert'>
                <div className="loader">
                    <div className="intern">
                    </div>
                    <div className="external-shadow">
                        <div className="central">
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Loader;
