/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import HeaderSwiper from '../components/molecular/HeaderSwiper'
import Moviecard from "../components/molecular/Moviecards";
import { useNavigate } from 'react-router-dom';
import { Box, } from '@mui/material';
import { BiMoviePlay } from "react-icons/bi";
import { GiDiamondTrophy } from "react-icons/gi";
import { useDispatch, useSelector } from 'react-redux';
import { LATEST_MOVIES, SEARCH_MOVIES, MOVIES, TOP_MOVIES, WEB_SERISE, MOVIES_ONLY } from '../redux/actions/moviesActions';
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import { IconContext } from "react-icons";
import Loader from '../components/small/Loader';
// import mixpanel from "mixpanel-browser";


const Home = () => {
    const Navigate = useNavigate();
    const [showMovies, setShowMovies] = useState('Browser');
    const [currentPage, setCurrentPage] = useState(1);
    const [searchCurrentPage, setSearchCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [browserPage, setBrowserPage] = useState(1);

    const dispatch = useDispatch();

    const latestMoviesList = useSelector((state) => state?.movies?.latestMovies);
    const searchMoviesList = useSelector((state) => state?.movies?.searchMovies);
    // const topmovielist = useSelector((state) => state?.movies?.movies);
    const newTopMovieList = useSelector((state) => state?.movies?.topmovie);  // recommeded movies
    const webseriselist = useSelector((state) => state?.movies?.webserise);
    const onlyMoviesData = useSelector((state) => state?.movies?.onlyMovies);
    const browserRef = useRef();
    const browserExecuteScroll = () =>
        browserRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });

    const topMovieListData = newTopMovieList?.data?.map((data) => {
        return {
            CID: data.cid,
            EpisodeData: data.episodedata,
            Info: {
                Description: data.info.description,
                Genere: data.info.genere,
                ImdbLink: data.info.imdblink,
                Language: data.info.language,
                PosterLink: data.info.posterlink,
                Quality: data.info.quality,
                Rating: data.info.rating,
                ScreenShots: data.info.screenshots,
                TrailerLink: data.info.trailerlink,
            },
            IsMovie: data.ismovie,
            MediaLinks: data.MediaLinks,
            Name: data.name,
            Subtitles: data.subtitles,
            ...data,
        }
    })
    const webSeariesConvertedData = webseriselist?.data?.docs?.map((data) => {
        return {
            CID: data.cid,
            EpisodeData: data.episodedata,
            Info: {
                Description: data.info.description,
                Genere: data.info.genere,
                ImdbLink: data.info.imdblink,
                Language: data.info.language,
                PosterLink: data.info.posterlink,
                Quality: data.info.quality,
                Rating: data.info.rating,
                ScreenShots: data.info.screenshots,
                TrailerLink: data.info.trailerlink,
            },
            IsMovie: data.ismovie,
            MediaLinks: data.MediaLinks,
            Name: data.name,
            Subtitles: data.subtitles,
            ...data,
        }
    })
    const onlyMoviesDataConvertedData = onlyMoviesData?.data?.docs?.map((data) => {
        return {
            CID: data.cid,
            EpisodeData: data.episodedata,
            Info: {
                Description: data.info.description,
                Genere: data.info.genere,
                ImdbLink: data.info.imdblink,
                Language: data.info.language,
                PosterLink: data.info.posterlink,
                Quality: data.info.quality,
                Rating: data.info.rating,
                ScreenShots: data.info.screenshots,
                TrailerLink: data.info.trailerlink,
            },
            IsMovie: data.ismovie,
            MediaLinks: data.MediaLinks,
            Name: data.name,
            Subtitles: data.subtitles,
            ...data,
        }
    })

    const latestMovies = useCallback((data) => {
        dispatch({ type: LATEST_MOVIES, payload: data });
    })

    const searchMovies = useCallback((data) => {
        dispatch({ type: SEARCH_MOVIES, payload: data });
    })

    // latest movies
    const movies = useCallback((data) => {
        dispatch({ type: MOVIES, payload: data });
    })

    const topmovies = useCallback((data) => {
        dispatch({ type: TOP_MOVIES, payload: data });
    })

    const webserise = useCallback((data) => {
        dispatch({ type: WEB_SERISE, payload: data });
    })

    const onlyMovies = useCallback((data) => {
        dispatch({ type: MOVIES_ONLY, payload: data });
    })

    useEffect(() => {
        latestMovies({ page: currentPage });
    }, [currentPage])

    useEffect(() => {
        searchMovies({ query: searchQuery, limit: 12, page: browserPage })
    }, [browserPage, searchQuery])

    useEffect(() => {
        movies({ page: currentPage, limit: 6 });
    }, [currentPage])

    useEffect(() => {
        topmovies({ page: currentPage, limit: 6 });
    }, [currentPage])
    useEffect(() => {
        webserise({ page: currentPage, limit: 10, isMovie: false });
    }, [currentPage])
    useEffect(() => {
        onlyMovies({ page: currentPage, limit: 10, isMovie: true });
    }, [currentPage])
    return (
        <React.Fragment>
            <div className='pt-[75px]'>
                <div style={{ background: '#2F4F4F ' }}
                    className='p-5'>
                    {/*<div style={{ background: 'linear-gradient(204deg, rgb(0, 0, 0) 30.79%, rgb(194 157 0) 80.6%)' }}*/}
                    {/*    // className='p-5'>*/}
                    <div>
                        <HeaderSwiper carouselData={topMovieListData} />
                    </div>
                    <div
                        className='text-white text-2xl md:text-3xl font-bold my-8 unbounded-font border-l-4 border-yellow-500 rounded-sm pl-2'>
                        RECOMMENDED 🔥
                    </div>
                    <div className='flex gap-4 mobile:flex-col'>
                        <div className='flex rounded-xl p-3 parent-movie-menu mt-4 w-4/6 mobile:w-full'>
                            <div
                                className='text-black font-medium p-2 text-base unbounded-font mobile:text-sm  cursor-pointer'
                                onClick={() => setShowMovies('Browser')}>Browser
                            </div>
                            <div
                                className='text-black font-medium p-2 text-base unbounded-font mobile:text-sm cursor-pointer'
                                onClick={() => setShowMovies('Movies')}>Movies
                            </div>
                            <div
                                className='text-black font-medium p-2 text-base unbounded-font mobile:text-sm cursor-pointer'
                                onClick={() => setShowMovies('TVshow')}> Web Series
                            </div>
                            <div
                                className='text-black font-medium p-2 text-base unbounded-font mobile:text-sm cursor-pointer'
                                onClick={() => setShowMovies('TopIMDb')}>Top View
                            </div>
                        </div>
                        <div className='flex rounded-xl p-1 parent-movie-menu mt-4 w-2/6 mobile:w-full'>
                            <div class="relative w-full text-black">
                                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                    <svg class="w-4 h-4 text-red-50 dark:text-black" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input onChange={(e) => setSearchQuery(e.target.value)} type="search"
                                    id="default-search"
                                    // w-full p-4 ps-10 text-base text-white-100 rounded-lg bg-transparent outline-none
                                    class="w-full p-4 ps-10 bg-transparent outline-none rounded-lg text-black font-bold unbounded-font opacity-40"
                                    placeholder="Search Your Movies" required />
                            </div>
                        </div>
                    </div>
                    {
                        showMovies === 'Browser' &&
                        <Box>
                            <div ref={browserRef}
                                className='text-white flex items-center border-l-4 border-yellow-500 pl-2 rounded-sm gap-3 text-2xl md:text-3xl font-bold mt-8 unbounded-font'>
                                <BiMoviePlay />
                                Browser 😎
                            </div>
                            {
                                searchMoviesList?.Movies?.length > 0 ?
                                    <div className='mt-7 grid gap-2 grid-cols-2 sm:grid-cols-4 md:grid-cols-5'>
                                        {
                                            searchMoviesList?.Movies?.map((movies, index) => {
                                                return (
                                                    <Moviecard moviesData={movies} />
                                                )
                                            })
                                        }
                                    </div> :
                                    <Loader />
                            }
                        </Box>
                    }
                    {/* //! pagimation */}
                    {
                        (showMovies == 'Browser') &&
                        <Box>

                            <ReactPaginate onClick={browserExecuteScroll}
                                previousLabel={
                                    <button
                                        className="flex items-center border border-1 border-amber-300 gap-2 px-6 py-3 font-sans text-xs font-bold text-center text-white uppercase align-middle transition-all rounded-lg select-none hover:bg-[#FFD700] disabled:pointer-events-none disabled:opacity-50 hover:text-black disabled:shadow-none"
                                        type="button">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                            aria-hidden="true" className="w-4 h-4">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"></path>
                                        </svg>

                                    </button>
                                }
                                nextLabel={
                                    <button
                                        className="flex items-center border border-1 border-amber-300 gap-2 px-6 py-3 font-sans text-xs font-bold text-center text-white uppercase align-middle transition-all rounded-lg select-none hover:bg-[#FFD700] disabled:pointer-events-none disabled:opacity-50 hover:text-black disabled:shadow-none"

                                        type="button">

                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                            aria-hidden="true" className="w-4 h-4">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"></path>
                                        </svg>
                                    </button>
                                }
                                pageCount={
                                    parseInt(searchMoviesList?.Count / 12) >= 999
                                        ? 999
                                        : searchMoviesList?.Count % 12 === 0
                                            ? parseInt(searchMoviesList?.Count / 12)
                                            : parseInt(searchMoviesList?.Count / 12) + 1
                                }
                                marginPagesDisplayed={0}
                                pageRangeDisplayed={4}
                                initialPage={
                                    searchQuery === "" ? currentPage - 1 : searchCurrentPage - 1
                                }
                                onPageChange={(event) =>
                                    searchQuery === ""
                                        ? (setBrowserPage(event.selected + 1), setSearchCurrentPage(1))
                                        : (setSearchCurrentPage(event.selected + 1), setBrowserPage(1))
                                }
                                containerClassName="flex flex-wrap justify-center items-center  my-10"
                                activeClassName="bg-[#FFD700]  h-10 max-h-[40px] w-10 max-w-[40px] rounded-lg text-center align-middle active"
                                pageClassName="mx-3  font-bold cursor-pointer text-black "
                                previousClassName="mx-3  cursor-pointer"
                                nextClassName="mx-3  cursor-pointer"
                            />
                        </Box>
                    }
                    <div>
                        {showMovies == 'TopIMDb' &&
                            <div>
                                <div
                                    className='text-white text-2xl md:text-3xl font-bold pt-1 unbounded-font flex gap-2 border-l-4 border-yellow-500 rounded-sm pl-2 mb-5'>
                                    <GiDiamondTrophy /> TOP VIEWED ✨
                                </div>
                                <div className='mt-3'>
                                    <div className='text-xl text-white font-medium'>
                                        - Top view is under contraction
                                    </div>

                                </div>
                            </div>
                        }
                    </div>
                    {
                        showMovies === 'Movies' &&
                        <Box>
                            <div
                                className='text-white text-2xl md:text-3xl font-bold mt-7 unbounded-font flex gap-2  border-l-4 border-yellow-500 pl-2 rounded-sm'>
                                LASTEST MOVIES 😎
                            </div>
                            {onlyMoviesDataConvertedData?.length > 0 ?
                                <div className='mt-7 grid gap-2 grid-cols-2 sm:grid-cols-4 md:grid-cols-5'>
                                    {
                                        onlyMoviesDataConvertedData?.map((movies, index) => {
                                            return (
                                                <Moviecard moviesData={movies} />
                                            )
                                        })
                                    }
                                </div>
                                : <Loader />
                            }
                        </Box>
                    }
                    <Box>

                        {/* <div className='mt-7 grid gap-2 grid-cols-2 sm:grid-cols-4 md:grid-cols-5'>
                            {latestmovieDetails?.slice(0, 6)?.map((movies, index) => {
                                return (
                                    <Moviecard moviesData={movies} />
                                )
                            })}
                        </div> */}
                    </Box>
                    {showMovies === 'TVshow' &&
                        <Box>
                            <div
                                className='text-white text-2xl md:text-3xl font-bold mt-7 unbounded-font flex gap-2  border-l-4 border-yellow-500 pl-2 rounded-sm'>
                                LASTEST WEB SERISE 💥
                            </div>
                            {
                                webSeariesConvertedData?.length > 0 ?

                                    <div className='mt-7 grid gap-2 grid-cols-2 sm:grid-cols-4 md:grid-cols-5'>
                                        {
                                            webSeariesConvertedData?.map((movies, index) => {
                                                return (
                                                    <Moviecard moviesData={movies} isWebSeries={true} />
                                                )
                                            })
                                        }
                                    </div> : <Loader />
                            }
                        </Box>
                    }
                    {(showMovies != 'Browser') &&
                        <Box>
                            <div>
                                <ReactPaginate
                                    previousLabel={
                                        <button
                                            className="flex items-center border border-1 border-amber-300 gap-2 px-6 py-3 font-sans text-xs font-bold text-center text-white uppercase align-middle transition-all rounded-lg select-none hover:bg-[#FFD700] disabled:pointer-events-none disabled:opacity-50 hover:text-black disabled:shadow-none"
                                            type="button">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                aria-hidden="true" className="w-4 h-4">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"></path>
                                            </svg>
                                            Previous
                                        </button>
                                    }
                                    nextLabel={
                                        <button
                                            className="flex items-center border border-1 border-amber-300 gap-2 px-6 py-3 font-sans text-xs font-bold text-center text-white uppercase align-middle transition-all rounded-lg select-none hover:bg-[#FFD700] disabled:pointer-events-none disabled:opacity-50 hover:text-black disabled:shadow-none"

                                            type="button">
                                            Next
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                aria-hidden="true" className="w-4 h-4">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"></path>
                                            </svg>
                                        </button>
                                    }
                                    breakLabel="..."
                                    breakClassName="mx-3 text-white font-bold"
                                    pageCount={
                                        parseInt(latestMoviesList?.Count / 12) >= 999
                                            ? 999
                                            : latestMoviesList?.Count % 12 === 0
                                                ? parseInt(latestMoviesList?.Count / 12)
                                                : parseInt(latestMoviesList?.Count / 12) + 1
                                    }
                                    marginPagesDisplayed={0}
                                    pageRangeDisplayed={4}
                                    initialPage={
                                        searchQuery === "" ? currentPage - 1 : searchCurrentPage - 1
                                    }
                                    onPageChange={(event) =>
                                        searchQuery === ""
                                            ? (setCurrentPage(event.selected + 1), setSearchCurrentPage(1))
                                            : (setSearchCurrentPage(event.selected + 1), setCurrentPage(1))
                                    }
                                    containerClassName="flex flex-wrap justify-center items-center  my-10"
                                    activeClassName="bg-[#FFD700] justify-center  h-10 max-h-[40px] w-10 max-w-[40px] rounded-lg items-center text-center align-middle active"
                                    pageClassName="mx-3  font-bold cursor-pointer text-black"
                                    previousClassName="mx-3  cursor-pointer"
                                    nextClassName="mx-3  cursor-pointer"
                                />
                            </div>
                        </Box>
                    }
                    <Box>

                        {/* <div className='mt-7 grid gap-2 grid-cols-2 sm:grid-cols-4 md:grid-cols-5'>
                            {latestwebseriseDetails?.slice(0, 6)?.map((movies, index) => {
                                return (
                                    <Moviecard moviesData={movies} />
                                )
                            })}
                        </div> */}
                    </Box>
                    <div className='  text-[#FFD700] text-lg md:text-4xl md:mb-[7px] font-bold mt-7 mb-5 '>
                        💥 GOOD REASONS TO CHOOSE US 💥
                    </div>
                    <div className='bg-[#FFD700] h-full w-full mb-1 p-5 rounded-lg'>
                        <h4 className=' text-sm mt-3 text-gray-950 unbounded-font'>1 . Very Fast Download
                            {/*(over*/}
                            {/*180,000 movies and TV Shows on one site). The ability to watch movies and TV Shows online in*/}
                            {/*a good HD quality.*/}
                        </h4>
                    {/*    <h4 className=' text-sm mt-3 text-gray-950 unbounded-font'>2 . Huge collection of videos*/}
                    {/*        /!*(over*!/*/}
                    {/*        /!*180,000 movies and TV Shows on one site). The ability to watch movies and TV Shows online in*!/*/}
                    {/*        /!*a good HD quality.*!/*/}
                    {/*    </h4>*/}
                    {/*    <h4 className='text-sm mt-3 text-gray-950 unbounded-font'>3 . Easy navigation*/}
                    {/*        /!*by genre or*!/*/}
                    {/*        /!*year..*!/*/}
                    {/*    </h4>*/}
                        <h4 className='text-sm mt-3 text-gray-950 unbounded-font'>2 . The best quality.
                            {/*Full movies and*/}
                            {/*TV Shows in HD 720p and FULL HD 1080p (totally free!). Soon there will be in 4K. At the*/}
                            {/*moment the number of HD videos on our site more than 180,000 and we constantly increasing*/}
                            {/*our library. Them 180,000 HD movies that you can watch online.*/}
                        </h4>
                        <h4 className='text-sm mt-3 text-gray-950 unbounded-font'>3 .
                            The mobile version of the website.
                            {/*You can watch movies/TV Shows directly from any mobile device in HD quality.*/}
                        </h4>
                    {/*    <h4 className='text-sm mt-3 text-gray-950 unbounded-font'>6 . Our website works perfect on any*/}
                    {/*        devices,*/}
                    {/*        /!*such us (Desktop, Laptop, Apple iPhone/iPad, Android Phone/Tablets and directly in*!/*/}
                    {/*        /!*your Smart TV browser).*!/*/}
                    {/*    </h4>*/}
                    </div>


                </div>
            </div>
        </React.Fragment>
    )
}

export default Home;

