/* eslint-disable */
import React, { useCallback, useEffect } from 'react'
import Movieinfo from "../components/molecular/Movieinfo";
import { Box } from "@mui/material";
import CustomCarousel from '../components/small/carousels/CustomCarousel';
import { RECOMMEND_MOVIES, SINGLE_MOVIES } from '../redux/actions/moviesActions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Loader from '../components/small/Loader';


const Moviepage = () => {
    const params = useParams();
    const { id } = params;
    const { state } = useLocation();

    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    const singleMoviesList = useSelector((state) => state?.movies?.singleMovies);
    const recommendMovies = useCallback((data) => {
        dispatch({ type: RECOMMEND_MOVIES, payload: data });
    }, [])

    const singleMovies = useCallback((data) => {
        dispatch({ type: SINGLE_MOVIES, payload: data });
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        recommendMovies();
        if (id) {
            singleMovies({ id: id })
        }
    }, [id])


    return (
        <React.Fragment>
            <div className='pt-[60px] '>
                <div style={{ background: '#2F4F4F' }} className='p-5'>
                    {/* <div className='flex flex-row-reverse'>
                        <button className="text-white mt-2 bg-transparent border border-solid border-white hover:bg-[#de3c42] hover:text-white active:bg-yellow-300-600 font-bold uppercase text-sm px-2 py-2 md:px-6 md:py-3 rounded-[7px] outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                            screenshorts
                        </button>
                    </div> */}
                    <div className='py-10 h-full'>
                        {/* {movieinfo?.map((movies, index) => {
                            return ( */}
                        {singleMoviesList?.length > 0 ?
                            <Movieinfo info={singleMoviesList?.[0]} isWebSeries={state?.isWebSeries} />
                            :
                            <Loader />
                        }
                        {/* )
                        })} */}
                    </div>

                    {/* <Box>
                        <div className='mt-7 grid gap-2 grid-cols-2 sm:grid-cols-4 md:grid-cols-5'>
                            {movieDetails?.slice(0, 6)?.map((movies, index) => {
                                return (
                                    <Moviecard moviesData={movies} />
                                )
                            })}
                        </div>
                    </Box> */}
                </div>
            </div>
        </React.Fragment>
    )
}

export default Moviepage;
