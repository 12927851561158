import React from "react";
import './../../small/carousels/carousel.css'
import { useNavigate } from "react-router-dom";

const CustomCarousel = ({ movieDetails }) => {
    const Navigate = useNavigate();
    return (
        <div className="parent-card-carousel">
            <div className="logos">
                <div className="logos-slide">
                    {movieDetails?.map((movie, index) => {
                        return (
                            <>
                                <div className='parent-product' onClick={() => {
                                    Navigate(`/movie/${movie.CID}`)
                                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                                }}>
                                    <span className='product-header'> {movie.name} </span>
                                    <img src={movie.Info.PosterLink} alt={index} className='project-card' />
                                </div>
                            </>
                        );
                    })}
                </div>
                {/* // ! use for future response */}
                {/* <div className="logos-slide">
                    {movieDetails?.map((movie, index) => {
                        return (
                            <>
                                <div className='parent-product'>
                                    <span className='product-header'> {movie.name} </span>
                                    <img src={movie?.Info?.PosterLink} alt={index} className='project-card' />
                                </div>
                            </>
                        );
                    })}
                </div> */}
            </div>

        </div>
    );
};
export default CustomCarousel;
