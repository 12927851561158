import {
    DOWNLOAD_MOVIES,
    DOWNLOAD_MOVIES_FAIL,
    DOWNLOAD_MOVIES_SUCCESS,
    LATEST_MOVIES,
    LATEST_MOVIES_FAIL,
    LATEST_MOVIES_SUCCESS, MOVIES, MOVIES_FAIL,
    MOVIES_ONLY,
    MOVIES_ONLY_FAIL,
    MOVIES_ONLY_SUCCESS, MOVIES_SUCCESS,
    RECOMMEND_MOVIES,
    RECOMMEND_MOVIES_FAIL,
    RECOMMEND_MOVIES_SUCCESS,
    SEARCH_MOVIES,
    SEARCH_MOVIES_FAIL,
    SEARCH_MOVIES_SUCCESS,
    SINGLE_MOVIES,
    SINGLE_MOVIES_FAIL,
    SINGLE_MOVIES_SUCCESS,
    TOP_MOVIES,
    TOP_MOVIES_FAIL,
    TOP_MOVIES_SUCCESS,
    WEB_SERISE,
    WEB_SERISE_FAIL,
    WEB_SERISE_SUCCESS,
} from "../actions/moviesActions";

const initialState = {
    latestMovies: {},
    recommendMovies: {},
    searchMovies: {},
    singleMovies: {},
    downloadMovies: {},
    isLoading: false,
    movies: {},
    topmovie: {},
    webserise: {},
    onlyMovies: {}
};

const moviesReducer = (state = initialState, { type, payload }) => {

    switch (type) {

        case LATEST_MOVIES: {
            return {
                ...state,
                isLoading: false
            }
        }

        case LATEST_MOVIES_SUCCESS: {
            return {
                ...state,
                latestMovies: payload,
                isLoading: false
            }
        }

        case LATEST_MOVIES_FAIL: {
            return {
                ...state,
                isLoading: false
            }
        }

        case RECOMMEND_MOVIES: {
            return {
                ...state,
                isLoading: false
            }
        }

        case RECOMMEND_MOVIES_SUCCESS: {
            return {
                ...state,
                recommendMovies: payload,
                isLoading: false
            }
        }

        case RECOMMEND_MOVIES_FAIL: {
            return {
                ...state,
                isLoading: false
            }
        }

        case SEARCH_MOVIES: {
            return {
                ...state,
                isLoading: false
            }
        }

        case SEARCH_MOVIES_SUCCESS: {
            return {
                ...state,
                searchMovies: payload,
                isLoading: false
            }
        }

        case SEARCH_MOVIES_FAIL: {
            return {
                ...state,
                isLoading: false
            }
        }

        case SINGLE_MOVIES: {
            return {
                ...state,
                isLoading: false
            }
        }

        case SINGLE_MOVIES_SUCCESS: {
            return {
                ...state,
                singleMovies: payload,
                isLoading: false
            }
        }

        case SINGLE_MOVIES_FAIL: {
            return {
                ...state,
                isLoading: false
            }
        }

        case DOWNLOAD_MOVIES: {
            return {
                ...state,
                isLoading: false,
            }
        }

        case DOWNLOAD_MOVIES_SUCCESS: {
            return {
                ...state,
                downloadMovies: payload,
                isLoading: false,
            }
        }

        case DOWNLOAD_MOVIES_FAIL: {
            return {
                ...state,
                isLoading: false
            }
        }
        case MOVIES: {
            return {
                ...state,
                isLoading: false
            }
        }

        case MOVIES_SUCCESS: {
            return {
                ...state,
                movies: payload,
                isLoading: false
            }
        }

        case MOVIES_FAIL: {
            return {
                ...state,
                isLoading: false
            }
        }

        case TOP_MOVIES: {
            return {
                ...state,
                isLoading: false
            }
        }

        case TOP_MOVIES_SUCCESS: {
            return {
                ...state,
                topmovie: payload,
                isLoading: false
            }
        }

        case TOP_MOVIES_FAIL: {
            return {
                ...state,
                isLoading: false
            }
        }

        case WEB_SERISE: {
            return {
                ...state,
                isLoading: false
            }
        }

        case WEB_SERISE_SUCCESS: {
            return {
                ...state,
                webserise: payload,
                isLoading: false
            }
        }

        case WEB_SERISE_FAIL: {
            return {
                ...state,
                isLoading: false
            }
        }

        case MOVIES_ONLY: {
            return {
                ...state,
                isLoading: false
            }
        }

        case MOVIES_ONLY_SUCCESS: {
            return {
                ...state,
                onlyMovies: payload,
                isLoading: false
            }
        }

        case MOVIES_ONLY_FAIL: {
            return {
                ...state,
                isLoading: false
            }
        }


        default: {
            return { ...state };
        }
    }
};
export default moviesReducer;
