import React from 'react'
import { BsBadgeHd } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import mixpanel from "mixpanel-browser";


const Moviecard = (props) => {
    const { moviesData, isWebSeries } = props
    var pattern = /^(.*?)\s*\(/;
    var match = pattern.exec(moviesData?.Name);
    const Navigate = useNavigate();

    var pattern = /^(.*?)\s*\(/;
    var finalName = pattern.exec(moviesData.Name);
    return (
        <React.Fragment>
            <div className="relative flexb overflow-hidden cursor-pointer"
                onClick={() => {
                    mixpanel.track("movie clicked", {
                        name: moviesData.Name,
                        dbID: moviesData.CID,
                        isWebSeries: isWebSeries ? isWebSeries : false
                    });
                    Navigate(`/movie/${moviesData.CID}`, { state: { isWebSeries: isWebSeries ? isWebSeries : false } })
                }
                }
            >
                <div className="absolute"></div>
                <div className="group relative m-0 flex rounded-xl ">
                    <div className="z-10 overflow-hidden rounded-xl border border-yellow-500 opacity-80 transition duration-300 ease-in-out group-hover:opacity-100 dark:border-gray-700 dark:opacity-100">
                        <img src={`https://images.cdn.kukufm.com/w:350/f:webp/q:100/${moviesData.Info.PosterLink}`}
                            alt={moviesData.Name} className="animate-fade-in scale-100 transform object-cover object-center opacity-100 transition duration-300 group-hover:scale-110" />
                    </div>
                    <div className="absolute bottom-[360px] z-20 m-0 pb-4 ps-4 transition duration-300 ease-in-out group-hover:-translate-y-1 group-hover:translate-x-3 group-hover:scale-110">
                        <h1 className="unbounded-font text-2xl font-bold text-white "></h1>
                    </div>
                </div>
                <h1 className="text-sm mt-1  text-white font-bold unbounded-font">
                    2024 - {moviesData?.Info?.Rating}</h1>
                <h1 className=" text-xl font-semibold text-[#FFD700] unbounded-font">{finalName[1]}</h1>
            </div>
        </React.Fragment>
    )
}
export default Moviecard
