import './App.css';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/molecular/Navbar';
import { Suspense } from 'react';
import Home from './pages/Home';
import Footer from './components/small/Footer';
import MoviePage from './pages/MoviePage'

function App() {
  // const dispatch = useDispatch();
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Suspense fallback={<div className='text-5xl text-blue'> Lodding </div>}>
          <Routes>
            <Route exact path='/' element={<Home />} />
            {/* <Route exact path='/carousel' element={<TvShows />} /> */}
            <Route exact path='/movie/:id' element={<MoviePage />} />
          </Routes>
        </Suspense>
        {/*<Footer />*/}
      </Router>
    </div>
  );
}

export default App;
